.create-button-news {
    justify-content: flex-end;
    align-items: flex-start;
}

.news-title {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #1C1E21;
}

.text-overflow2{
    overflow:hidden;
    display:inline-block;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    max-width: 250px;
    /* height: 56px; */
}